@font-face {
  font-family: "Source Sans Pro";
  src: url(../assets/fonts/SourceSansPro-Regular.ttf) format("truetype");
}

html, body { margin: 0;} 

body {
  font-family: "Source Sans Pro", sans-serif;
}

h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 64px;
}

h2 {
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 48px;
}

h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
}

h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
}
